/* eslint-disable no-undef */
/* eslint-disable require-yield */
/* eslint-disable no-redeclare */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

// Product state
// Product state
import {
  GET_VM_STATUS,
  GET_NETT_SETTLEMENT,
  GET_SNAPSHOT,
  GET_ROLE,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { device_list as deviceMap } from 'Apps/device';
import {
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
  forceDeepSet,
  safeDeepDel,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
// time
const momenttz = require('moment-timezone');
const moment = require('moment');

function* get_role(payload) {
  yield put(loading(GET_ROLE));
  let email = safeDeepGet(payload, 'payload');
  const firebaseInstance = getInstance();
  let data = yield call(firebaseInstance.account.getRole, email);
  if(data.code == -1){
    email =  email.charAt(0).toUpperCase() + email.slice(1);
    data = yield call(firebaseInstance.account.getRole, email);
  }
  const role = safeDeepGet(data, ['body', 'role'], 'super_admin');
  localStorage.setItem('role', JSON.stringify(role));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const permision = safeDeepGet(access, ['body', 'permision'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const accessNav = safeDeepGet(access, ['body', 'navigation'], []);
  if (allBp == 'true') {
    localStorage.setItem('allBp', true);
  } else {
    localStorage.setItem('allBp', false);
  }
  if (permision == 'true') {
    localStorage.setItem('permision', true);
  } else {
    localStorage.setItem('permision', false);
  }
  localStorage.setItem('accessBP', JSON.stringify(accessBP));
  localStorage.setItem('accessNav', JSON.stringify(accessNav));
  yield put(
    result(GET_ROLE, { code: Result.SUCCESS, body: access })
  );
}
function* get_vm_status() {
  yield put(loading(GET_VM_STATUS));
  const firebaseInstance = getInstance();
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointBody = wsResult.body;

    const businessPointList = [];
    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'device_id'], '');
      businessPointMap[deviceId] = safeDeepGet(businessPointBody, id);
    }

    wsResult = yield call(deviceMap);
    if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
      const devices = safeDeepGet(wsResult, ['body', 'devices']);
      const bpStatus = {
        bpTotal: 0,
        bpOnline: 0,
        bpOffline: 0,
        bpAlert: 0,
      };
      let i = 0;
      for (const deviceId in businessPointMap) {
        const online = safeDeepGet(
          devices,
          [deviceId, 'online', 'is_online'],
          false
        );
        safeDeepSet(businessPointMap, [deviceId, 'id'], i++);
        safeDeepSet(businessPointMap, [deviceId, 'online'], online);
        if (allBp == 'true') {
          if (online) {
            bpStatus.bpOnline += 1;
          } else {
            bpStatus.bpOffline += 1;
          }
          bpStatus.bpTotal += 1;
          businessPointList.push(businessPointMap[deviceId]);
        } else if (allBp == 'false') {
          if (arr_bp.includes(deviceId)) {
            if (online) {
              bpStatus.bpOnline += 1;
            } else {
              bpStatus.bpOffline += 1;
            }
            bpStatus.bpTotal += 1;
            businessPointList.push(businessPointMap[deviceId]);
          }
        }
      }
      var statusMap = { card: Object.values(bpStatus), map: businessPointList };

      yield put(
        result(GET_VM_STATUS, { code: Result.SUCCESS, body: statusMap })
      );
      return;
    }
  }
  yield put(result(GET_VM_STATUS, { code: Result.FAILED }));
}


function* get_snapshot(payload) {
  yield put(loading(GET_SNAPSHOT));
  const data = safeDeepGet(payload, 'payload', {});
  const month = safeDeepGet(data, 'month', 0);
  const year = safeDeepGet(data, 'year', 0);
  const firebaseInstance = getInstance();
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  let wsResult = yield call(firebaseInstance.snapshotMonthly.list);
  const code = safeDeepGet(wsResult, 'code', Result.UNKNOWN_ERROR);
  if (code != Result.SUCCESS) {
    yield put(result(GET_SNAPSHOT, { code: Result.FAILED }));
    return;
  }
  var lastMonth = year + '-' + ('0' + month).slice(-2);
  if (month == 0) {
    lastMonth = year - 1 + '-' + ('0' + 12).slice(-2);
  }

  const lastMonthBody = safeDeepGet(wsResult, ['body', lastMonth], {});
  let lastAmount = 0
  if(allBp == "false"){
    const bp = safeDeepGet(lastMonthBody,'business_point', {})
    for(const i in bp){
      if(arr_bp.includes(i)){
        lastAmount += safeDeepGet(bp, [i, 'total', 'total_sales'], 0)
      }
    }
  } else {
    lastAmount = safeDeepGet(lastMonthBody, ['total', 'total_sales'], 0);
  }
  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0, 23,59,59);
  const payload_daily = {
    type: 'DASHBOARD',
    month: month,
    year: year,
    date: [start, end],
  };
  wsResult = yield call(firebaseInstance.snapshotDaily.list, payload_daily);
  const earning = {
    1: {
      x: '01',
      y: 0,
    },
    2: {
      x: '02',
      y: 0,
    },
    3: {
      x: '03',
      y: 0,
    },
    4: {
      x: '04',
      y: 0,
    },
    5: {
      x: '05',
      y: 0,
    },
    6: {
      x: '06',
      y: 0,
    },
    7: {
      x: '07',
      y: 0,
    },
    8: {
      x: '08',
      y: 0,
    },
    9: {
      x: '09',
      y: 0,
    },
    10: {
      x: '10',
      y: 0,
    },
    11: {
      x: '11',
      y: 0,
    },
    12: {
      x: '12',
      y: 0,
    },
    13: {
      x: '13',
      y: 0,
    },
    14: {
      x: '14',
      y: 0,
    },
    15: {
      x: '15',
      y: 0,
    },
    16: {
      x: '16',
      y: 0,
    },
    17: {
      x: '17',
      y: 0,
    },
    18: {
      x: '18',
      y: 0,
    },
    19: {
      x: '19',
      y: 0,
    },
    20: {
      x: '20',
      y: 0,
    },
    21: {
      x: '21',
      y: 0,
    },
    22: {
      x: '22',
      y: 0,
    },
    23: {
      x: '23',
      y: 0,
    },
    24: {
      x: '24',
      y: 0,
    },
    25: {
      x: '25',
      y: 0,
    },
    26: {
      x: '26',
      y: 0,
    },
    27: {
      x: '27',
      y: 0,
    },
    28: {
      x: '28',
      y: 0,
    },
    29: {
      x: '29',
      y: 0,
    },
    30: {
      x: '30',
      y: 0,
    },
    31: {
      x: '31',
      y: 0,
    },
  };
  let currentAmount = 0;
  let currentMdrAmount = 0;
  const dailyBody = safeDeepGet(wsResult, 'body');
  if (allBp == 'false') {
    for (const item in dailyBody) {
      for (const bp in arr_bp) {
        const device_id = arr_bp[bp];
        const dateSlice = item.slice(-2);
        const id = Number(dateSlice);
        let bpMap = safeDeepGet(dailyBody, [item, 'business_point']);
        if(typeof bpMap == "string"){
          bpMap = JSON.parse(bpMap);
        }
        forceDeepSet(
          earning,
          [id.toString(), 'y'],
          safeDeepGet(bpMap, [device_id, 'total', 'total_sales'], 0)
        );
        // const nettAmount = safeDeepGet(
        //   dailyBody,
        //   [item, 'business_point', device_id, 'total', 'total_sales'],
        //   // total - sharing
        //   0
        // );
        const nettAmount  = safeDeepGet(bpMap, [device_id, 'total', 'total_sales'], 0);
        currentAmount += nettAmount;  
        const mdr= 0.007 * nettAmount;
        currentMdrAmount += mdr 
      }
    }
  } else if (allBp == 'true'){
    for (const item in dailyBody) {
      const dateSlice = item.slice(-2);
      const id = Number(dateSlice);
      forceDeepSet(
        earning,
        [id.toString(), 'y'],
        safeDeepGet(dailyBody, [item, 'total', 'total_sales'], 0)
      );
      const nett = safeDeepGet(
        dailyBody,
        [item, 'total', 'total_nett'],
        // total - sharing
        0
      );
      const nettAmount = safeDeepGet(
        dailyBody,
        [item, 'total', 'total_sales'],
        // total - sharing
        0
      );
      currentAmount += nettAmount;  
      const mdr= 0.007 * nettAmount;
      currentMdrAmount += mdr 
    }
  }
  const today = new Date();
  const twoDaysBefore = new Date();
  twoDaysBefore.setHours(0);
  twoDaysBefore.setMinutes(0);
  twoDaysBefore.setSeconds(0);
  twoDaysBefore.setDate(today.getDate() - 1);
  const timezone = 'Asia/Jakarta';
  moment.tz.setDefault(timezone);
  const now = momenttz.tz(today, timezone);
  let Date_1 = now.clone().subtract(2, 'days').startOf('days');
  let Date_2 = now.clone().subtract(0, 'days').endOf('days');
  if (today.getDate() === 1) {
    if (today.getMonth() === month) {
      Date_1 = now.clone().subtract(0, 'days').startOf('days');
      Date_2 = now.clone().subtract(0, 'days').endOf('days');
    } else {
      Date_1 = now.clone().subtract(2, 'days').startOf('days');
      Date_2 = now.clone().subtract(1, 'days').endOf('days');
    }
  } else if ( today.getDate() === 2){
    if (today.getMonth() === month) {
      Date_1 = now.clone().subtract(1, 'days').startOf('days');
      Date_2 = now.clone().subtract(0, 'days').endOf('days');
    } else {
      Date_1 = now.clone().subtract(3, 'days').startOf('days');
      Date_2 = now.clone().subtract(2, 'days').endOf('days');
    }
  }
  const startTime = Date_1.valueOf();
  const endTime = Date_2.valueOf();
  const DaysBefore = new Date(startTime);
  const DaysAfter = new Date(endTime);

  if (today.getMonth() === month) {
    const payload_trx = {
      dateRange: [DaysBefore, DaysAfter],
    };
    wsResult = yield call(firebaseInstance.transaction.list, payload_trx);
    const trxBody = safeDeepGet(wsResult, 'body', {});
    if (allBp == 'false') {
      for (const trx in trxBody) {
        const device_id = safeDeepGet(
          trxBody,
          [trx, 'product', 'device_id'],
          ''
        );
        if (arr_bp.includes(device_id)) {
          const timeStamp = safeDeepGet(
            trxBody,
            [trx, 'time', 'timestamp'],
            {}
          );
          const date = new Date(timeStamp).getDate();
          if (
            safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
            'settlement'
          ) {
            earning[date].y += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            const mdr = safeDeepGet(trxBody, [trx, 'payment', 'fee', 'mdr_qris'], 0);
            const share_revenue = safeDeepGet(
              trxBody,
              [trx, 'payment', 'fee', 'platform_sharing_revenue'],
              0
            );
            currentMdrAmount += mdr
            currentAmount += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
          }
        }
      }
    } else {
      for (const trx in trxBody) {
        const timeStamp = safeDeepGet(trxBody, [trx, 'time', 'timestamp'], {});
        const date = new Date(timeStamp).getDate();
        if (
          safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
          'settlement'
        ) {
          earning[date].y += safeDeepGet(
            trxBody,
            [trx, 'payment', 'amount'],
            0
          );
          const mdr = safeDeepGet(trxBody, [trx, 'payment', 'fee', 'mdr_qris'], 0);
          const share_revenue = safeDeepGet(
            trxBody,
            [trx, 'payment', 'fee', 'platform_sharing_revenue'],
            0
          );
          currentMdrAmount += mdr
          currentAmount += safeDeepGet(trxBody, [trx, 'payment', 'amount'], 0);
        }
      }
    }
  } else if (today.getDate() === 1) {
    if (today.getMonth() - 1 == month) {
      const payload_trx = {
        dateRange: [DaysBefore, DaysAfter],
      };
      wsResult = yield call(firebaseInstance.transaction.list, payload_trx);
      const trxBody = safeDeepGet(wsResult, 'body', {});
      if (allBp == 'false') {
        for (const trx in trxBody) {
          const device_id = safeDeepGet(
            trxBody,
            [trx, 'product', 'device_id'],
            ''
          );
          if (arr_bp.includes(device_id)) {
            const timeStamp = safeDeepGet(
              trxBody,
              [trx, 'time', 'timestamp'],
              {}
            );
            const date = new Date(timeStamp).getDate();
            if (
              safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
              'settlement'
            ) {
              const mdr = safeDeepGet(trxBody, [trx, 'payment', 'fee', 'mdr_qris'], 0);
              const share_revenue = safeDeepGet(
                trxBody,
                [trx, 'payment', 'fee', 'platform_sharing_revenue'],
                0
              );
              currentMdrAmount += mdr
              earning[date].y += safeDeepGet(
                trxBody,
                [trx, 'payment', 'amount'],
                0
              );
              currentAmount += safeDeepGet(
                trxBody,
                [trx, 'payment', 'amount'],
                0
              );
              
            }
          }
        }
      } else {
        for (const trx in trxBody) {
          const timeStamp = safeDeepGet(
            trxBody,
            [trx, 'time', 'timestamp'],
            {}
          );
          const date = new Date(timeStamp).getDate();
          if (
            safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
            'settlement'
          ) {
            earning[date].y += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            const mdr = safeDeepGet(trxBody, [trx, 'payment', 'fee', 'mdr_qris'], 0);
            const share_revenue = safeDeepGet(
              trxBody,
              [trx, 'payment', 'fee', 'platform_sharing_revenue'],
              0
            );
            currentMdrAmount += mdr
            currentAmount += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
          }
        }
      }
    }
  } else if (today.getDate() === 2) {
    if (today.getMonth() - 1 == month) {
      const payload_trx = {
        dateRange: [DaysBefore, DaysAfter],
      };
      console.log(payload_trx)
      wsResult = yield call(firebaseInstance.transaction.list, payload_trx);
      const trxBody = safeDeepGet(wsResult, 'body', {});
      if (allBp == 'false') {
        for (const trx in trxBody) {
          const device_id = safeDeepGet(
            trxBody,
            [trx, 'product', 'device_id'],
            ''
          );
          if (arr_bp.includes(device_id)) {
            const timeStamp = safeDeepGet(
              trxBody,
              [trx, 'time', 'timestamp'],
              {}
            );
            const date = new Date(timeStamp).getDate();
            if (
              safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
              'settlement'
            ) {
              const mdr = safeDeepGet(trxBody, [trx, 'payment', 'fee', 'mdr_qris'], 0);
              const share_revenue = safeDeepGet(
                trxBody,
                [trx, 'payment', 'fee', 'platform_sharing_revenue'],
                0
              );
              currentMdrAmount += mdr
              earning[date].y += safeDeepGet(
                trxBody,
                [trx, 'payment', 'amount'],
                0
              );
              currentAmount += safeDeepGet(
                trxBody,
                [trx, 'payment', 'amount'],
                0
              );
              
            }
          }
        }
      } else {
        for (const trx in trxBody) {
          const timeStamp = safeDeepGet(
            trxBody,
            [trx, 'time', 'timestamp'],
            {}
          );
          const date = new Date(timeStamp).getDate();
          if (
            safeDeepGet(trxBody, [trx, 'detail', 'transaction_status']) ==
            'settlement'
          ) {
            earning[date].y += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
            const mdr = safeDeepGet(trxBody, [trx, 'payment', 'fee', 'mdr_qris'], 0);
            const share_revenue = safeDeepGet(
              trxBody,
              [trx, 'payment', 'fee', 'platform_sharing_revenue'],
              0
            );
            currentMdrAmount += mdr
            currentAmount += safeDeepGet(
              trxBody,
              [trx, 'payment', 'amount'],
              0
            );
          }
        }
      }
    }
  }
  const chart_data = [];
  for (const i in earning) {
    const amount = safeDeepGet(earning, [i, 'y'], 0);
    safeDeepDel(earning, [i, 'y']);
    safeDeepSet(earning, [i, 'y'], Number(amount.toFixed(1)));
    chart_data.push(earning[i]);
  }

  let sum_current = currentAmount;
  let sum_prev = lastAmount;
  let temp_change = 0.0;
  if (sum_prev == 0 && sum_current > 0) {
    temp_change = 100.0;
  } else if (sum_prev == 0 && sum_current == 0) {
    temp_change = 0.0;
  } else {
    temp_change = (sum_current / sum_prev - 1.0) * 100.0;
  }
  const nett_settlement = {
    card_values: {
      card_mdr_current: currentMdrAmount,
      card_sum_current: sum_current,
      card_sum_prev: sum_prev,
      card_change: temp_change,
    },
    chart_data: chart_data,
  };

  yield put(
    result(GET_SNAPSHOT, {
      code: Result.SUCCESS,
      body: nett_settlement,
    })
  );
}

function* dashboardSaga() {
  yield takeEvery(GET_VM_STATUS, get_vm_status);
  // yield takeEvery(GET_NETT_SETTLEMENT, get_nett_settlement);
  yield takeEvery(GET_SNAPSHOT, get_snapshot);
  yield takeEvery(GET_ROLE, get_role);
}
function sum_month(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);

    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function sum_per_date(nsMap) {
  let now = new Date();
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    {
      nsMap
        ? (now = val.time.firestore_timestamp.toDate())
        : (now = new Date());
    }
  }
  var resultArr = [];
  var dateArr = [];
  const month = now.getMonth();
  const year = now.getFullYear();
  const date = new Date(year, month, 1);
  const dates = [];
  let obj = {};
  while (date.getMonth() === month) {
    const day = new Date(date);
    dates.push(('0' + day.getDate()).slice(-2));
    date.setDate(date.getDate() + 1);
  }
  for (const i in dates) {
    obj = { x: dates[i], y: 0 };
    resultArr.push(obj);
  }

  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    let val_date = val.time.firestore_timestamp
      .toDate()
      .toLocaleString(undefined, { day: '2-digit' });

    var index = dateArr.indexOf(val_date);
    if (index == -1) {
      dateArr.push(val_date);
      if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
        resultArr[Number(val_date) - 1].y += val.payment.amount;
      }
    } else {
      if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
        resultArr[Number(val_date) - 1].y += val.payment.amount;
      }
    }
  }
  return resultArr;
}

export default dashboardSaga;
